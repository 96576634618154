







































import { Vue, Component } from "vue-property-decorator";
import { Input, Button } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import faqStore from "@/store/modules/faq";

@Component({
  components: {
    Input,
    Button
  }
})
export default class FAQClass extends Vue {
  get AdminRouter() {
    return AdminRouter;
  }
  get FAQ() {
    return faqStore.faq;
  }

  async created() {
    let that = this;
    this.reset();
    const id = that.$route.query["id"];
    if (id) {
      faqStore.getFAQById(id);
    }
  }

  async cancel() {
    this.reset();
    this.$router.push(AdminRouter.FAQList);
  }
  async submit() {
    await faqStore.saveFAQ();
    commonstore.showLoading();

    this.$snotify.success("FAQ  Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.FAQList);
    this.reset();
  }
  reset() {
    faqStore.resetField();
  }
}
